import React, { useState } from 'react';
import './App.css';
import cineteriaLogo from './images/cineteria.png';
import jQuery from 'jquery';

function App() {
  const [success, setSuccess] = useState(false);
  const [check, setCheck] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  return (
    <div className='main'>
      <div className='content'>
        {!success && 
        <>
        <div className='header'>
          <img src={cineteriaLogo} className='logo' />
          <div className='mainHeader'>
            Join the waitlist.
          </div>
          <div className='secondaryHeader'>
            Be the first to know when we GO live.
          </div>
        </div>
        <div className='inputContainer'>
          <input type="email" className='emailInput' placeholder="Enter your email" id="email" onChange={(e) => handleEmail(e)} />
          {!submitting &&
          <button className='joinButton' onClick={handleSubmit}>Join</button>
          }
          {submitting &&
          <div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div>
          }
        </div>
        <div className='error'>
          {error}
        </div>
        </>
        }
        {success &&
        <>
        <div className='mainHeader'>Signed up!</div>
        <svg class={`checkmark ${check ? 'active' : {}}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> 
          <circle class={`checkmark__circle ${check ? 'active' : {}}`} cx="26" cy="26" r="25" fill="none"/> 
          <path class={`checkmark__check ${check ? 'active' : {}}`} fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8"/>
        </svg>
        <div className='secondaryHeader'>We will email you as soon as we are up and running.</div>
        </>
        }
      </div>
    </div>
  );

    function handleEmail(e) {
      setEmail(e.target.value);
    }

    async function handleSubmit(e) {
      setSubmitting(true);
      setError('');
      jQuery.ajax({
        url: `https://go.cineteria.com/api/waitlist.php`,
        type: 'POST',
        data: {'email': email},
        success: function (response) {
            var data = JSON.parse(response);
            if (!data.error) {
              setSuccess(true);
              setCheck(true);
            } else {
              setError(data.error);
            }
        }
    })
      setSubmitting(false);
    }

}

export default App;
